<template>
	<div class="grid">
		<template v-if="loader==true">
		<div class="col-12">
			<center><i class="pi pi-spin pi-spinner" style="font-size: 3rem"></i></center>
		</div>
		</template>
		<template v-if="ingresoCostumer==false">
			<template v-for="(customer,index) in customers" :key="index">
				<div class="col-12 lg:col-6 disabled">
					<div class="card height-90 flex flex-column m-3">
						<div class="flex justify-content-center p-3 rounded-normal content-alt-bgcolor">
							<img :src="'layout/images/customers/'+customer.url" alt="img-customer" class="img-fluid">
						</div>
						<div class="flex flex-column align-items-center mt-3">
							<h5 class="mb-2">{{customer.name}}</h5>
						</div>
						
						<div class="flex justify-content-between align-items-center pt-3 mt-auto">
							<small class="muted-text"></small>
							<template v-if="customer.status==1">
								<Button class="p-button-raised" label="Ingresar" @click="showSuccess(customer)"></Button>
							</template>
						</div>
					</div>
				</div>
			</template>
		</template>
		<template v-else>
			<h2>Bienvenidos, cargando ingreso...<i class="pi pi-spin pi-compass" style="font-size: 2rem"></i></h2>
			<!-- <div class="col-12 lg:col-12">
			<Toolbar class="mb-4">
				
				<template v-slot:end>
					<Button label="Volver" icon="pi pi-chevron-left" class="mr-2 mb-2" @click="salirCustomer"></Button><br>
				</template>
			</Toolbar>
			</div> -->
		</template>
	</div>
</template>

<script>
// import axios from 'axios'
import EventBus from '../../event-bus';
export default {
	data() {
		return {
			message: [],
			customers: [],
			username:null,
			loader: false,
			email:null,
			count: 0,
			ingresoCostumer: false
		}
	},
	mounted(){
		this.cargar_empresas();
	},
	methods:{
		showSuccess(customer) {
			// document.cookie = "NameCustomer="+customer.customer.name;
			// document.cookie = "IdCustomer="+customer.customer.id;
			// document.cookie = "UrlImgCustomer="+customer.customer.url;
			// document.cookie = "IdUsuarioRol="+customer.id;
			// document.cookie = "UserAdminCustomer="+JSON.stringify(customer.useradmincustomer);


			document.cookie = "NameCustomer="+customer.name;
			document.cookie = "IdCustomer="+customer.id_customer;
			document.cookie = "UrlImgCustomer="+customer.url;
			document.cookie = "IdUsuarioRol="+customer.id;
			this.ingresoCostumer = true;
			// console.log(index);
			EventBus.emit('show-img-customer',customer.url);
			// console.log(cookie[cookieName]);
			// console.log(this.$store.getters['infoNavigation/access']);
			
			this.$router.push({ path: this.$store.getters['infoNavigation/rol']+'/inicio'});
		},
		cargar_empresas(){
			const cookieName = 'Email';
			const IdRol = 'IdRol';
			this.loader = true;
			this.customers = [];
			let cookie = {};
			document.cookie.split(';').forEach(function(el) {
			let [key,value] = el.split('=');
			cookie[key.trim()] = value;
			})
			// console.log(cookie[cookieName]);
			// console.log(cookie[IdRol]);
			// console.log(this.$store.getters['infoNavigation/IdRol']);

			var email = cookie[cookieName];
			var id_rol = cookie[IdRol];
			// axios.post('/cargar_empresas', {email: email}).then(response => {
			// 	this.customers = response.data.data;
			// 	console.log(this.customers);
			// 	this.loader = false;
			// }).catch(e => {
				// console.log(id_rol);
			// });
			
			var _that = this;
			var url = 'cargar_empresas';
			window.api.call('post', url, {email: email,id_rol: id_rol}).then( function(response) {
				if(response.status == 200){
					_that.customers = response.data.data;
					// console.log(_that.customers);
					_that.loader = false;
				}
			}).catch(function (err) {
				console.log(err);
			});
		},
		salirCustomer(){
			this.ingresoCostumer = false;
		},
	}
}
</script>

<style scoped>

</style>
